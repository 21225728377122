
import { Vue, Component } from "vue-property-decorator";
import { Mutation, State } from "vuex-class";
@Component
export default class FFPersonCenter extends Vue {
  message = "";
  @Mutation updateUserInfo: any;
  @State userinfo: any;
  toindex(): void {
    this.$router.push({ name: "FF-Index" });
  }
  exit(): void {
    this.updateUserInfo("");
    this.$router.push({ name: "FF-Index" });
  }

  mounted() {
    this.$get("/big_home/we_get_personal_info", {
      phone: this.userinfo.phone,
    }).then((res: any) => {
      this.message = res.data.data.apply_msg;
    });
  }
}
